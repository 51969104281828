import { trackCtaClicked, trackCtaViewed, CtaEventDataType } from '@zola-helpers/client/dist/es';

export const postShipCrossSellTrackingData: CtaEventDataType = {
  business_unit: 'COMMERCE',
  cta_id: 'gift-tracker-ty-post-ship',
  cta: 'Shop Thank You Cards',
  location: 'Gift Tracker',
  position: 0,
  section: 'GiftTracker_PostShip',
};

export const handlePostShipViewTracking = (): void => {
  trackCtaViewed(postShipCrossSellTrackingData);
};

export const handlePostShipClickTracking = (): void => {
  trackCtaClicked(postShipCrossSellTrackingData);
};
