import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import ThankYouNoteManager from '@zola/zola-ui/src/assets/images/icons/thank_you_note_manager.svg';
import AddExternalIcon from '../../../assets/images/Overview/NextStepsModule/AddExternalIcon.svg';
import AddGiftsIcon from '../../../assets/images/Overview/NextStepsModule/AddGiftsIcon.svg';
import Album from '../../../assets/images/Disbursement/album.svg';
import CashFundsIcon from '../../../assets/images/Overview/NextStepsModule/CashFundsIcon.svg';
import CompletionDiscountIcon from '../../../assets/images/Overview/NextStepsModule/CompletionDiscountIcon.svg';
import CouplePhotoIcon from '../../../assets/images/Overview/NextStepsModule/CouplePhotoIcon.svg';
import CrateAndBarrelIcon from '../../../assets/images/Overview/NextStepsModule/CrateAndBarrelIcon.svg';
import ExpertAdviceIcon from '../../../assets/images/Overview/NextStepsModule/ExpertAdviceIcon.svg';
import InvitePartnerIcon from '../../../assets/images/Overview/NextStepsModule/InvitePartnerIcon.svg';
import InvitesPaper from '../../../assets/images/Overview/NextStepsModule/InvitesPaper.svg';
import KitchenTabletopIcon from '../../../assets/images/Overview/NextStepsModule/KitchenTabletopIcon.svg';
import PublishYourRegistryIcon from '../../../assets/images/Overview/NextStepsModule/PublishYourRegistryIcon.svg';
import RegistryAdvisorIcon from '../../../assets/images/Overview/NextStepsModule/RegistryAdvisorIcon.svg';
import ShipGiftsIcon from '../../../assets/images/Overview/NextStepsModule/ShipGiftsIcon.svg';
import ShippingAddressIcon from '../../../assets/images/Overview/NextStepsModule/ShippingAddressIcon.svg';
import ShowerIcon from '../../../assets/images/Overview/NextStepsModule/ShowerIcon.svg';
import ThankYouNoteIcon from '../../../assets/images/Overview/NextStepsModule/ThankYouNoteIcon.svg';
import WeddingDateIcon from '../../../assets/images/Overview/NextStepsModule/WeddingDateIcon.svg';
import WeddingDetailsIcon from '../../../assets/images/Overview/NextStepsModule/WeddingDetailsIcon.svg';
import WeddingWebsiteIcon from '../../../assets/images/Overview/NextStepsModule/WeddingWebsiteIcon.svg';

import { handlePostShipClickTracking, handlePostShipViewTracking } from './helpers';

dayjs.extend(duration);

interface getMessagesDictionaryType {
  [key: string]: {
    copy?: string;
    action: {
      type: string;
      target: string;
    };
    icon: string;
    ctaIcon: string;
    key: string;
    tracking?: {
      clickTrackingOverride?: () => void;
      viewTrackingOverride?: () => void;
    };
  };
}

const getMessagesDictionary = (daysUntilEvent?: number): getMessagesDictionaryType => ({
  PRODUCTS_NOT_ADDED: {
    copy: 'Add your first gift',
    action: {
      type: 'LINK',
      target: '/shop/collection/top-registry-gifts-this-week',
    },
    icon: AddGiftsIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'PRODUCTS_NOT_ADDED',
  },
  CASH_FUNDS_NOT_ADDED: {
    copy: 'Create a honeymoon fund',
    action: {
      type: 'LINK',
      target: '/shop/honeymoon-cash-funds',
    },
    icon: CashFundsIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'CASH_FUNDS_NOT_ADDED',
  },
  ADD_WEDDING_DETAILS: {
    copy: 'Add your guest count so we can make recs',
    action: {
      type: 'LINK',
      target: '/registry/planner/init',
    },
    icon: WeddingDetailsIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'ADD_WEDDING_DETAILS',
  },
  ADD_MORE_KITCHEN_TABLETOP: {
    copy: 'Shop gifts guests <3 to give',
    action: {
      type: 'LINK',
      target: '/shop/collection/guests-favorites',
    },
    icon: KitchenTabletopIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'ADD_MORE_KITCHEN_TABLETOP',
  },
  UPSELL_CRATE_AND_BARREL: {
    copy: "Add Crate & Barrel's best sellers",
    action: {
      type: 'LINK',
      target: '/shop/partner-boutique/crate-barrel',
    },
    icon: CrateAndBarrelIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'UPSELL_CRATE_AND_BARREL',
  },
  SUGGEST_REGISTRY_ADVISOR: {
    copy: 'Ask a Registry Advisor all your Qs',
    action: {
      type: 'LINK',
      target: '/advisor',
    },
    icon: RegistryAdvisorIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'SUGGEST_REGISTRY_ADVISOR',
  },
  ADD_WEDDING_SHOWER_GIFTS: {
    copy: 'Having a shower? Fill your registry up!',
    action: {
      type: 'LINK',
      target: '/shop/collection/showers',
    },
    icon: ShowerIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'ADD_WEDDING_SHOWER_GIFTS',
  },
  CHECK_OUT_EXPERT_ADVICE: {
    copy: 'Get expert registry advice',
    action: {
      type: 'LINK',
      target: '/expert-advice/registry',
    },
    icon: ExpertAdviceIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'CHECK_OUT_EXPERT_ADVICE',
  },
  EVENT_DATE_NOT_ADDED: {
    copy: 'Add your wedding date to stay on track',
    action: {
      type: 'LINK',
      target: '/account/settings/wedding',
    },
    icon: WeddingDateIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'EVENT_DATE_NOT_ADDED',
  },
  PARTNER_NOT_ADDED: {
    copy: 'Add your partner to your account',
    action: {
      type: 'LINK',
      target: '/account/settings/invitepartner',
    },
    icon: InvitePartnerIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'PARTNER_NOT_ADDED',
  },
  SHIPPING_ADDRESS_NOT_ADDED: {
    copy: 'Tell us where to ship your gifts',
    action: {
      type: 'LINK',
      target: '/account/settings/shipping',
    },
    icon: ShippingAddressIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'SHIPPING_ADDRESS_NOT_ADDED',
  },
  PHOTO_NOT_ADDED: {
    copy: 'Add a photo and greeting for guests',
    action: {
      type: 'LINK',
      target: '/registry/share',
    },
    icon: CouplePhotoIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'PHOTO_NOT_ADDED',
  },
  EXTERNAL_GIFTS_NOT_ADDED: {
    copy: 'Add gifts from another store',
    action: {
      type: 'LINK',
      target: '/wedding-registry/add-from-other-stores',
    },
    icon: AddExternalIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'EXTERNAL_GIFTS_NOT_ADDED',
  },
  REGISTRY_NOT_PUBLISHED: {
    copy: 'Make your registry visible to guests',
    action: {
      type: 'LINK',
      target: '/registry/share',
    },
    icon: PublishYourRegistryIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'REGISTRY_NOT_PUBLISHED',
  },
  NO_WEDDING_ACCOUNT: {
    copy: 'Create a FREE wedding website',
    action: {
      type: 'LINK',
      target: '/wedding-planning/website',
    },
    icon: WeddingWebsiteIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'NO_WEDDING_ACCOUNT',
  },
  SHIPPABLE_GIFTS_UNAVAILABLE: {
    copy: 'Find out how to get your gifts home',
    action: {
      type: 'LINK',
      target: '/registry/gift-tracker/your-gifts',
    },
    icon: ShipGiftsIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'SHIPPABLE_GIFTS_UNAVAILABLE',
  },
  COMPLETION_DISCOUNT_UNAVAILABLE: {
    copy: "Don't forget: get 20% off post-wedding",
    action: {
      type: 'LINK',
      target: '/faq/115002837692-does-zola-offer-a-post-wedding-or-completion-discount-',
    },
    icon: CompletionDiscountIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'COMPLETION_DISCOUNT_UNAVAILABLE',
  },
  PRODUCTS_ADDED: {
    copy: 'Shop 100s of new arrivals',
    action: {
      type: 'LINK',
      target: '/shop/collection/new-arrivals',
    },
    icon: AddGiftsIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'PRODUCTS_ADDED',
  },
  CASH_FUNDS_ADDED: {
    copy: 'Create a custom cash fund (idea: puppy fund!)',
    action: {
      type: 'LINK',
      target: '/shop/honeymoon-cash-funds',
    },
    icon: CashFundsIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'CASH_FUNDS_ADDED',
  },
  EVENT_DATE_ADDED: {
    copy: daysUntilEvent
      ? `Looking forward to ${dayjs().add(daysUntilEvent, 'days').format('MM/DD/YYYY')}!`
      : undefined,
    action: {
      type: 'LINK',
      target: '/account/settings/wedding',
    },
    icon: WeddingDateIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'EVENT_DATE_ADDED',
  },
  PARTNER_ADDED: {
    copy: 'Join our Facebook Community',
    action: {
      type: 'LINK',
      target: 'https://www.facebook.com/groups/zolacommunity/',
    },
    icon: InvitePartnerIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'PARTNER_ADDED',
  },
  SHIPPING_ADDRESS_ADDED: {
    copy: 'Edit your shipping address',
    action: {
      type: 'LINK',
      target: '/account/settings/shipping',
    },
    icon: ShippingAddressIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'SHIPPING_ADDRESS_ADDED',
  },
  PHOTO_ADDED: {
    copy: 'Add personal touches for guests',
    action: {
      type: 'LINK',
      target: '/registry/share',
    },
    icon: CouplePhotoIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'PHOTO_ADDED',
  },
  EXTERNAL_GIFTS_ADDED: {
    copy: 'Add a gift from another store',
    action: {
      type: 'LINK',
      target: '/wedding-registry/apps',
    },
    icon: AddExternalIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'EXTERNAL_GIFTS_ADDED',
  },
  REGISTRY_PUBLISHED: {
    copy: 'Share your registry ',
    action: {
      type: 'LINK',
      target: '/faq/category/115000342372-registry/115000736211-sharing-your-registry',
    },
    icon: PublishYourRegistryIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'REGISTRY_PUBLISHED',
  },
  HAS_WEDDING_ACCOUNT: {
    copy: 'Get $50 when you refer a friend to Zola',
    action: {
      type: 'LINK',
      target: '/wedding-registry/invite',
    },
    icon: WeddingWebsiteIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'HAS_WEDDING_ACCOUNT',
  },
  SHIPPABLE_GIFTS_AVAILABLE: {
    copy: 'Ship your gifts (yay!)',
    action: {
      type: 'LINK',
      target: '/registry/gift-tracker/your-gifts',
    },
    icon: ShipGiftsIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'SHIPPABLE_GIFTS_AVAILABLE',
  },
  COMPLETION_DISCOUNT_AVAILABLE: {
    copy: 'Use your 20% post-wedding discount',
    action: {
      type: 'LINK',
      target: '/faq/115002837692-does-zola-offer-a-post-wedding-or-completion-discount-',
    },
    icon: CompletionDiscountIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'COMPLETION_DISCOUNT_AVAILABLE',
  },
  THANK_YOUS_NOT_ORDERED: {
    copy: 'Shop custom thank you cards',
    action: {
      type: 'LINK',
      target: '/wedding-planning/thank-you-cards/shop',
    },
    icon: ThankYouNoteIcon,
    ctaIcon: 'zolaicon-plus',
    key: 'THANK_YOUS_NOT_ORDERED',
  },
  THANK_YOUS_ORDERED: {
    copy: 'Get $50 when you refer a friend to Zola',
    action: {
      type: 'LINK',
      target: '/wedding-registry/invite',
    },
    icon: ThankYouNoteIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'THANK_YOUS_ORDERED',
  },
  SHOP_INVITES: {
    copy: 'Shop Invites + Paper',
    action: {
      type: 'LINK',
      target: '/wedding-planning/paper',
    },
    icon: InvitesPaper,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'SHOP_INVITES',
  },
  MANAGE_REGISTRY: {
    copy: 'Manage Your Registry',
    action: {
      type: 'LINK',
      target: '/my-registry',
    },
    icon: AddGiftsIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'MANAGE_REGISTRY',
  },
  SHOP_REGISTRY: {
    copy: 'Shop Your Registry',
    action: {
      type: 'LINK',
      target: '/my-registry',
    },
    icon: AddGiftsIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'SHOP_REGISTRY',
  },
  BROWSE_NEW_ARRIVALS: {
    copy: 'Browse New Arrivals',
    action: {
      type: 'LINK',
      target: '/shop/collection/new-arrivals',
    },
    icon: CompletionDiscountIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'BROWSE_NEW_ARRIVALS',
  },
  MANAGE_GIFTS: {
    copy: 'Manage Your Gifts',
    action: {
      type: 'LINK',
      target: '/registry/gift-tracker/your-gifts',
    },
    icon: ShippingAddressIcon,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'MANAGE_GIFTS',
  },
  START_YOUR_ALBUM: {
    copy: 'Start Your Wedding Album',
    action: {
      type: 'LINK',
      target: '/wedding-albums/shop',
    },
    icon: Album,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'START_YOUR_ALBUM',
  },
  GIFT_TRACKER_THANK_YOU_CARDS_CROSS_SELL: {
    copy: 'Shop Thank You Cards',
    action: {
      type: 'LINK',
      target: '/wedding-planning/thank-you-cards/shop',
    },
    icon: ThankYouNoteManager,
    ctaIcon: 'zolaicon-right-arrow',
    key: 'GIFT_TRACKER_THANK_YOU_CARDS_CROSS_SELL',
    tracking: {
      clickTrackingOverride: handlePostShipClickTracking,
      viewTrackingOverride: handlePostShipViewTracking,
    },
  },
});

export default getMessagesDictionary;
